<template>
  <div class="mt-8">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <!--history payment-->
        <vs-table :data="initData.payments" class="fix-table-input-background">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap text-center">#</vs-th>
            <vs-th class="whitespace-no-wrap text-center">ID</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Tgl. Diajukan</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Jml Komisi</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Jml Bayar</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Jml Sisa</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Status Approval</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm" :class="{'bg-warning text-black font-semibold': isItemHighlighted(item)}">
              <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
              <vs-td class="whitespace-no-wrap">#ID{{ item.id }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_diajukan }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_komisi | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_bayar | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.jml_sisa | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">
                <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs leading-none text-white rounded-full" :class="['bg-' + item.status_approval_color]">{{ item.status_approval }}</span>
              </vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            </vs-tr>
            <vs-tr class="text-sm">
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
              <vs-td class="text-right">{{ summary.grandTotalJmlKomisi | idr }}</vs-td>
              <vs-td class="text-right">{{ summary.grandTotalJmlBayar | idr }}</vs-td>
              <vs-td class="text-right">{{ summary.grandTotalJmlSisa | idr }}</vs-td>
              <vs-td></vs-td>
              <vs-td></vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TabPayment',
  props: {
    initData: {
      default: null,
      type: Object
    }
  },
  computed: {
    summary () {
      return {
        grandTotalJmlKomisi: _.sumBy(this.initData.payments, item => parseFloat(item.jml_komisi)),
        grandTotalJmlBayar: _.sumBy(this.initData.payments, item => parseFloat(item.jml_bayar)),
        grandTotalJmlSisa: _.sumBy(this.initData.payments, item => parseFloat(item.jml_sisa))
      }
    }
  },
  methods: {
    isItemHighlighted (item) {
      return item.id === parseInt(this.$route.params.idKomisiPayment)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.komisiApproval.activeTab
      this.$store.commit('approvals/komisiApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
